// // // // // // // // // // //
//
// Filter Item
//
// // // // // // // // // // //

.filter-box {
  @include transition(0.15s);
  padding: 0.1em 1em;
  border: 1px solid $roughwood-brown;
  cursor: pointer;
  &.filter-active {
    position: relative;
    background-color: $roughwood-brown;
    padding-left: 2em;
    &:after,
    &:before {
      @include position(absolute, 50% auto auto 4px);
      content: '';
      background-color: $roughwood-grey;
      width: 10px;
      height: 1px;
    }
    &:before {
      @include transform(rotate(45deg));
    }
    &:after {
      @include transform(rotate(-45deg));
    }
    a {
      color: $roughwood-grey;
    }
  }
}
