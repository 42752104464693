// HOME PAGE CAROUSEL
.slick-slide {
  outline: none !important;
}
.home-gallery {
  z-index: 2;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  float: left;
  margin-bottom: 0px;
  .slick-slide {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    zoom: 1;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba($dark, 0.3);
      z-index: 9;
    }
  }
}
.no-touchevents {
  .home-gallery .slick-slide {
    background-attachment: fixed;
  }
}
// PRODUCT IMAGES CAROUSEL
.product-container {
  .image-box {
    margin-bottom: 0px;
    .slick-next,
    .slick-prev {
      height: 40px;
      width: 80px;
      border-radius: 90px 90px 0 0;
      background: transparent;
      z-index: 100;
      @extend %default-transition;
      &:before {
        content: '';
        width: 2px;
        height: 40px;
        opacity: 1;
        background: $roughwood-brown;
        @include position(absolute, 50% auto auto 31%);
        @include transform(translateY(-50%) rotate(45deg));
        @extend %default-transition;
      }
      &:after {
        content: '';
        width: 2px;
        height: 40px;
        background: $roughwood-brown;
        @include position(absolute, auto 31% 50% auto);
        @include transform(translateY(50%) rotate(-45deg));
        @extend %default-transition;
      }
    }
    .slick-next {
      @include position(absolute, 50% 0px auto auto);
      @include transform(translateY(-50%) rotate(90deg));
    }
    .slick-prev {
      @include position(absolute, 50% auto auto -60px);
      @include transform(translateY(-50%) rotate(270deg));
    }
    .slick-dots {
      bottom: -17.5px;
      button {
        &:before {
          content: '\2666 \FE0E';
          font-size: 14px;
          color: $roughwood-grey;
          opacity: 1;
        }
      }
      .slick-active {
        button {
          &:before {
            color: $product-background;
            opacity: 1;
          }
        }
      }
    }
  }
}

.filter {
  width: 100%;
  height: 100vh;
  background-color: rgba(#000, 0.6);
  @include position(fixed, 0px auto auto 0px);
  z-index: 100;
}
// PRODUCT PAGE MODALS
.related-products {
  height: 0px;
  .looks-modal {
    height: 70vh;
    top: 45%;
    background-color: $roughwood-grey-dark;
  }
  .slick-slider {
    float: left;
    height: 70vh;
    width: 100%;
    position: relative;
    .slick-slide {
      height: 70vh;
      position: relative;
      cursor: pointer;
      a {
        @include position(absolute, 0px 0px 0px 0px);
        z-index: 100;
      }
      img {
        height: 80%;
        margin-top: 5%;
        @include position(relative, auto auto auto 50%);
        @include transform(translateX(-50%));
      }
      .name {
        @include position(absolute, 30px auto auto 12.5%);
        font-size: 20px;
        color: $product-background;
        font-family: $font-body;
        opacity: 0;
        @extend %default-transition;
        z-index: 10;
      }
      .price {
        font-size: 14px;
        color: $product-background;
        font-family: $font-body;
        opacity: 0;
        @extend %default-transition;
        @include position(absolute, 50px auto auto 12.5%);
        z-index: 10;
      }
    }
    .slick-slide:active {
      outline: none;
    }
    .slick-next,
    .slick-prev {
      height: 40px;
      width: 80px;
      border-radius: 90px 90px 0 0;
      background: $roughwood-grey-dark;
      z-index: 100;
      @extend %default-transition;
      &:before {
        content: '';
        width: 2px;
        height: 40px;
        opacity: 1;
        background: $roughwood-brown;
        @include position(absolute, 50% auto auto 31%);
        @include transform(translateY(-50%) rotate(45deg));
        @extend %default-transition;
      }
      &:after {
        content: '';
        width: 2px;
        height: 40px;
        background: $roughwood-brown;
        @include position(absolute, auto 31% 50% auto);
        @include transform(translateY(50%) rotate(-45deg));
        @extend %default-transition;
      }
    }
    .slick-next {
      @include position(absolute, 50% -60px auto auto);
      @include transform(translateY(-50%) rotate(90deg));
    }
    .slick-prev {
      @include position(absolute, 50% auto auto -60px);
      @include transform(translateY(-50%) rotate(270deg));
    }
    .slick-dots {
      bottom: 50px;
      button {
        &:before {
          content: '\2666 \FE0E';
          font-size: 14px;
          color: $roughwood-grey;
          opacity: 1;
        }
      }
      .slick-active {
        button {
          &:before {
            color: $product-background;
            opacity: 1;
          }
        }
      }
    }
  }
}
.related-products.filter {
  height: 0px;
  overflow: hidden;
}
.related-products.show-related-products {
  height: 100vh;
}
// LOOKBOOK PAGE CAROUSEL
.lookbook {
  .slick-slider {
    float: left;
    height: 60vh;
    width: 100%;
    position: relative;
    .slick-slide {
      height: 60vh;
      position: relative;
      cursor: pointer;
      a {
        @include position(absolute, 0px 0px 0px 0px);
        z-index: 100;
      }
      img {
        height: 80%;
        margin-top: 5%;
        @include position(relative, auto auto auto 50%);
        @include transform(translateX(-50%));
      }
      .name {
        @include position(absolute, 5px auto auto 12.5%);
        font-size: 20px;
        color: $roughwood-grey;
        font-family: $font-body;
        opacity: 0;
        @extend %default-transition;
        z-index: 10;
      }
      .price {
        font-size: 14px;
        color: $roughwood-grey;
        font-family: $font-body;
        opacity: 0;
        @extend %default-transition;
        @include position(absolute, 30px auto auto 12.5%);
        z-index: 10;
      }
    }
    .slick-slide:active {
      outline: none;
    }
    .slick-next,
    .slick-prev {
      height: 40px;
      width: 80px;
      z-index: 100;
      @extend %default-transition;
      &:before {
        content: '';
        width: 2px;
        height: 40px;
        opacity: 1;
        background: $roughwood-brown;
        @include position(absolute, 50% auto auto 31%);
        @include transform(translateY(-50%) rotate(45deg));
        @extend %default-transition;
      }
      &:after {
        content: '';
        width: 2px;
        height: 40px;
        background: $roughwood-brown;
        @include position(absolute, auto 31% 50% auto);
        @include transform(translateY(50%) rotate(-45deg));
        @extend %default-transition;
      }
    }
    .slick-next {
      @include position(absolute, 50% 0px auto auto);
      @include transform(translateY(-50%) rotate(90deg));
    }
    .slick-prev {
      @include position(absolute, 50% auto auto 0px);
      @include transform(translateY(-50%) rotate(270deg));
    }
    .slick-dots {
      bottom: 0px;
      button {
        &:before {
          content: '\2666 \FE0E';
          font-size: 14px;
          color: $roughwood-grey;
          opacity: 1;
        }
      }
      .slick-active {
        button {
          &:before {
            color: $roughwood-brown;
            opacity: 1;
          }
        }
      }
    }
  }
}
.no-touchevents {
  .lookbook,
  .product,
  .lifestyle-products,
  .featured-products-slick,
  .product-gallery-slick,
  .designer-products {
    .slick-slide:hover {
      .name {
        opacity: 1;
      }
      .price {
        opacity: 1;
      }
    }
  }
  .lookbook {
    .slick-slider {
      .slick-next:hover,
      .slick-prev:hover {
        background: $roughwood-brown;
        &:after {
          background: $product-background;
        }
        &:before {
          background: $product-background;
        }
      }
    }
  }
}

.touchevents {
  .lookbook,
  .product,
  .lifestyle-products,
  .featured-products-slick,
  .product-gallery-slick,
  .designer-products {
    .slick-slide {
      .name {
        opacity: 1;
      }
      .price {
        opacity: 1;
      }
    }
  }
}
