// // // // // // // // // // //
//
// Filter Aside Menu
//
// // // // // // // // // // //

$f-collapse: 900px;
$f-big-collapse: 1050px;

aside.filter-module {
  @include transition(0.5s);
  position: fixed;
  top: 50px;
  right: -200vw;
  background-color: $roughwood-grey-dark;
  background-color: darken($roughwood-grey-dark, 5%);
  width: 100%;
  height: calc(100vh - 50px);
  z-index: 999;
  opacity: 0;
  overflow-y: auto;
  &.loaded {
    display: block;
  }
  &.reveal {
    @include transform(translateX(-200vw));
    opacity: 1;
  }
  button {
    font-family: 'Alegreya', serif;
    background-color: transparent;
    color: $roughwood-brown;
  }
  #filter-message {
    @extend %default-transition;
    @include position(absolute, auto auto 140px 10%);
    font-size: 24px;
    width: auto;
    cursor: unset;
    @media all and (min-width: 1300px) {
      left: 15%;
    }
    &.hidden {
      display: none;
    }
  }
}
// - - - - - - - - - - -
// Back btn
// - - - - - - - - - - -
.back-btn {
  @include center;
  top: 10px;
  font-size: 18px;
}
// - - - - - - - - - - - - -
// Expand / Close All btns
// - - - - - - - - - - - - -
.expand-close-all-btns {
  position: absolute;
  top: 10px;
  right: 10px;
  svg {
    position: relative;
    top: 3px;
    width: 15px;
    height: 15px;
    pointer-events: none;
  }
  .close-all-btn {
    svg {
      @include transform(rotate(180deg));
    }
  }
  @include mediaMin(900px) {
    display: none;
  }
}

// - - - - - - - - - - -
// No matches message
// - - - - - - - - - - -
.no-match-message {
  @extend %center;
  top: 20vh;
  width: 90%;
  max-width: 400px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  @include mediaMin(600px) {
    font-size: 22px;
  }
}

// - - - - - - - -
// Filter BLOCKS
// - - - - - - - -
.filter-blocks {
  @include flexbox();
  width: 100%;
  max-width: 1400px;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0 auto;
  > li {
    flex-basis: 100%;
    &:last-child {
      @include flexbox();
      flex-basis: 100%;
      justify-content: center;
      button {
        margin: 0.25em 2em;
        font-family: $font-body;
        text-transform: uppercase;
        font-weight: 400;
        color: $roughwood-grey;
      }
    }
  }
  h4 {
    color: $roughwood-brown;
    font-size: 2.4em;
    width: 100%;
    margin: 0;
    padding-left: 5%;
    @include mediaMin(400px) {
      text-align: center;
      padding-left: 0;
    }
  }
  // columns to 3x2 row (filter blocks)
  @include mediaMin($f-collapse) {
    flex-direction: row;
    > li {
      flex-basis: 50%;
    }
  }
  @include mediaMinMax($f-collapse, $f-big-collapse) {
    > li {
      &.materials-block {
        @include transform(translateX(-50%));
        margin-left: 50%;
      }
    }
  }
  @include mediaMin($f-big-collapse) {
    > li {
      flex-basis: 33.33%;
      &.size-block,
      &.materials-block {
        margin-top: 40px;
      }
      &.size-block {
        @include transform(translateX(-20%));
        margin-left: 20%;
      }
    }
  }
}

//
// Filter BLOCK
//
.filter-block {
  position: relative;
  min-height: 60px;
  margin: 0;
  padding-top: 10px;
  overflow: hidden;
}

//
// Filter title / type
//
.filter-title {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 2.2em;
  padding-bottom: 12px;
  text-align: left;
  &::after {
    @extend %center;
    content: '';
    position: absolute;
    display: block;
    background-color: $roughwood-brown;
    width: 90%;
    height: 1px;
    bottom: 0;
  }
  &:hover {
    ~ .arrow-down-box {
      border-color: darken($roughwood-brown, 5%);
      svg {
        fill: darken($roughwood-brown, 5%);
      }
    }
  }
  &.flip {
    ~ .arrow-down-box {
      svg {
        @include transform(translate(-50%, -50%) rotateX(180deg));
      }
    }
  }
}
//
//
//
.arrow-down-box {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 15px;
  right: 30px;
  pointer-events: none;
  border-radius: 2px;
  border: 1px solid lighten($roughwood-brown, 10%);
  @include mediaMin($f-collapse) {
    display: none;
  }
  // Chevron down icon
  .icon-chevron-sharp-down {
    @extend %default-transition;
    @extend %abs-middle;
    fill: lighten($roughwood-brown, 10%);
    width: 30px;
    height: 30px;
  }
}

//
// Clear / Apply buttons
//
.filter-btns {
  margin-top: 20px;
  @include mediaMin($f-collapse) {
    margin-top: 50px;
  }
}
