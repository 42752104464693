// // // // // // // // // // //
//
// Hamburger Icon
//
// // // // // // // // // // //

.hamburger-container {
  width: inherit;
  height: inherit;
}
.hamburger {
  @include position(absolute, 23px auto auto 7px);
  background: $roughwood-grey;
  width: 36px;
  height: 3px;
  padding: 0;
  overflow: visible;
  z-index: 10;
  &:before,
  &:after {
    @include position(absolute, -10px auto auto 0px);
    content: '';
    background: $roughwood-grey;
    width: 36px;
    height: 3px;
  }
  &:after {
    @include position(absolute, auto auto -10px 0px);
  }
  @include mediaMin(64em) {
    display: none;
  }
}
.hamburger-open {
  @include transform(rotate(90deg));
}

// HAMBURGER ANIMATION HANDLING

// OPEN
.nav-open .hamburger {
  @include animation(burger-open 0.3s forwards);
  &:before {
    @include animation(burger-top-open 0.3s forwards);
  }
  &:after {
    @include animation(burger-bot-open 0.3s forwards);
  }
}
// CLOSED
.hamburger {
  @include animation(burger-closed 0.3s forwards);
  &:before {
    @include animation(burger-top-closed 0.3s forwards);
  }
  &:after {
    @include animation(burger-bot-closed 0.3s forwards);
  }
}
