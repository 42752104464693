aside.collections-menu {
  @include position(fixed, 50px auto auto 0px);
  @include transform(translateX(300%));
  @include transition(0.5s);
  background-color: darken($roughwood-grey-dark, 5%);
  width: 100%;
  height: calc(100vh - 50px);
  z-index: 999;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  &.reveal {
    @include transform(translateX(0%));
  }
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    li {
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-size: cover;
      background-repeat: no-repeat;
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        @include position(absolute, 0px 0px 0px 0px);
        background-color: black;
        opacity: 0.1;
      }
    }
  }
  a {
    text-align: center;
    font-size: 3em;
    color: white;
    position: relative;
    z-index: 3;
    span:after {
      background-color: white;
    }
  }
  @include mediaMin(50em) {
    ul {
      flex-direction: row;
      flex-wrap: wrap;
      li {
        width: 50%;
      }
    }
    a {
      font-size: 4.8em;
    }
  }
}
