.desktop-nav {
  display: none;
  margin-left: 2.5%;
  float: left;
  > li {
    position: relative;
    margin: 0 2em;
    float: left;
  }
  li.on-sale {
    font-weight: 700;
    font-style: italic;
  }
  p {
    font-family: $font-body;
    color: $roughwood-grey;
    font-size: 1.8em;
    line-height: 50px;
    position: relative;
    letter-spacing: 1px;
    float: left;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
  span {
    @extend %center;
    position: relative;
    height: 50px;
    float: left;
    &:after {
      @include position(absolute, auto auto 12px 50%);
      @include transform(translateX(-50%));
      @extend %default-transition;
      content: '';
      background-color: $roughwood-grey;
      display: block;
      width: 0%;
      height: 1px;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
  }
}

ul.desktop-nav ul.sub-nav {
  @include position(fixed, 50px auto auto 0px);
  @include transition(0.75s);
  background-color: #fff;
  width: 100vw;
  max-height: 0px;
  overflow: hidden;
  > li {
    width: 50%;
    float: left;
    position: relative;
    &:after {
      @include position(absolute, 75% auto auto 0%);
      @include transform(translateY(-50%));
      content: '';
      width: 1px;
      height: 100%;
      background-color: $roughwood-grey;
    }
    &:first-child:after {
      display: none;
    }
  }
  &.four-up {
    @include transform(translateX(-50%));
    width: 50vw;
    left: 32%;
    a.cta {
      display: block;
      font-size: 1em;
      max-width: 80%;
      line-height: 1em;
      margin: 0 auto;
      margin-top: 2em;
    }
  }
  &.expand {
    max-height: 1000px;
    padding-bottom: 5%;
    &.extend {
      padding-bottom: 7.5%;
    }
  }
}

.no-touchevents {
  // .desktop-nav > li > p:hover {
  //     + ul.sub-nav {
  //         max-height: 1000px;
  //         padding-bottom: 5%;
  //         &.extend {
  //             padding-bottom: 7.5%;
  //         }
  //     }
  // }
  // ul.sub-nav:hover {
  //     max-height: 1000px;
  //     padding-bottom:5%;
  //     &.extend {
  //         padding-bottom: 7.5%;
  //     }
  // }
  .desktop-nav p:hover {
    span:after {
      width: 100%;
    }
  }
}

.desktop-nav > li:nth-child(2) ul.sub-nav > li {
  width: 25%;
}

@media all and (min-width: 64em) {
  .main-nav {
    .desktop-nav {
      display: block;
    }
  }
}
