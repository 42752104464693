// // // // // // // // // // //
//
// Cart Page
//
// // // // // // // // // // //
.cart {
  @include clearfix();
  position: relative;
  background-color: $roughwood-grey;
  width: 100%;
  padding-bottom: 50px;
  float: left;
  .content {
    max-width: 800px;
  }
  .summary {
    h2 {
      font-size: 20px;
      color: $roughwood-brown;
      width: 100%;
      text-align: left;
      margin-bottom: 30px;
    }
    .form-container {
      margin-top: 2em;
      input,
      label {
        width: 90%;
        margin: 5px 2.5%;
      }
      label {
        padding: 0;
      }
    }
  }
}
.review,
.summary {
  width: 100%;
  float: left;
  padding: 25px 0;
}
.summary {
  width: 95%;
  margin: 0 2.5%;
  margin-top: 40px;
}
.icon-line-4 {
  @include transform(rotate(180deg));
  display: block;
  width: 80%;
  max-width: 400px;
  margin: 30px auto 0;
  use {
    fill: $roughwood-brown;
  }
}
.review {
  h2 {
    color: $roughwood-brown;
    width: 95%;
    margin: 0 2.5%;
    text-align: left;
    font-size: 20px;
  }
  h2:nth-of-type(2) {
    margin-top: 10px;
  }
  ul {
    width: 100%;
    float: left;
    margin-top: 50px;
  }
  li {
    width: 95%;
    float: left;
    margin: 5px 2.5%;
    height: 120px;
    position: relative;
    background: rgba($roughwood-brown, 0.15);
    img {
      height: 90%;
      float: left;
      @include position(relative, 5% auto auto 5px);
    }
    p {
      font-size: 12px;
      width: 70%;
      float: left;
      color: $roughwood-brown;
      font-family: $font-body;
      margin: 5px 2.5%;
    }
    p.name {
      margin-top: 30px;
    }
    .remove {
      @extend %default-transition;
      @include position(absolute, 5% 5% auto auto);
      @include appearance(none);
      background-color: transparent;
      color: $roughwood-brown;
      height: 30px;
      width: 75px;
      font-family: $font-body;
      font-size: 14px;
      outline: none;
      cursor: pointer;
      border-radius: none;
    }
  }
  .quantity {
    @include position(absolute, auto 5% 5% auto);
    height: 25px;
    width: 75px;
    p {
      float: left;
      font-size: 14px;
      line-height: 25px;
      text-align: center;
      margin: 0;
      width: 25px;
    }
    button {
      @extend %default-transition;
      @include appearance(none);
      position: relative;
      background-color: transparent;
      color: $roughwood-brown;
      height: 25px;
      width: 25px;
      font-family: $font-body;
      font-size: 14px;
      cursor: pointer;
      float: left;
      border-radius: none;
      outline: none;
    }
    .decrease {
      &:after {
        @extend %absolute-middle;
        content: '-';
        font-size: 30px;
        top: 35%;
      }
    }
    .increase {
      &:after {
        @extend %absolute-middle;
        content: '+';
        font-size: 20px;
        top: 35%;
      }
    }
  }
  .no-item {
    position: relative;
    p {
      margin: 0;
      font-family: $font-roughwood;
      font-size: 20px;
      @extend %absolute-middle;
      width: auto;
    }
  }
}
.summary {
  h3 {
    width: 100%;
    text-align: left;
    color: $roughwood-brown;
    font-family: $font-body;
    font-size: 20px;
    margin: 20px 0;
  }
  h3:nth-of-type(1) {
    margin-top: 30px;
  }
  h4 {
    font-size: 14px;
    color: $roughwood-brown;
    font-family: $font-body;
    margin: 10px 0;
  }
}
.pricing-container {
  float: left;
  width: 100%;
  padding: 0 2.5%;
  background: rgba($roughwood-brown, 0.15);
}
.button-container {
  position: relative;
  width: 100%;
  float: left;
  padding-bottom: 100px;
  .confirm-button {
    margin: 0;
    border: 2px solid transparent;
    @extend %absolute-middle;
    min-width: 200px;
    float: none;
    @extend %default-transition;
  }
  .inactive {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
}
@media all and (min-width: 650px) {
  .review {
    li {
      p {
        width: auto;
        font-size: 16px;
        line-height: 120px;
        margin: 0 2.5%;
      }
      p.name {
        margin-top: 0;
      }
      .remove {
        top: 50%;
        @include transform(translateY(-50%));
      }
    }
    .quantity {
      top: 50%;
      bottom: 0;
      right: 20%;
      @include transform(translateY(-50%));
    }
  }
  .button-container {
    width: 50%;
    padding-bottom: 150px;
  }
}
// NO Touch
.no-touchevents {
  .cart {
    .remove:hover {
      background-color: transparent;
      border-color: $roughwood-brown-nav;
      color: $roughwood-brown;
    }
    .increase:hover,
    .decrease:hover {
      background-color: transparent;
      border-color: $roughwood-brown-nav;
      color: $roughwood-brown;
    }
    .summary {
      button:hover {
        border: 2px solid $roughwood-brown;
        background: transparent;
        color: $roughwood-brown;
      }
    }
  }
}
