 .product {
    width: 100%;
    @include clearfix();
    background-color: $roughwood-grey-dark;
    overflow-x: hidden;
    overflow-y: hidden;
    .filter {
        background-color: rgba(#000, 0.75);
    }
}
.drift-zoom-pane {
    background-color: $roughwood-grey-dark;
}
.breadcrumbs-container {
    height: 30px;
    width: 100%;
    position: relative;
    top: 60px;
    margin-left: 20px;
    z-index: 1;
}
.breadcrumbs {
    list-style-type: none;
    width: 100%;
    padding-left: 0;
    li {
        display: inline;
        font-family: $font-roughwood;
        color: $product-background;
        font-size: 14px;
    }
}
.product-container {
    width: 100%;
    float: left;
    position: relative;
}

.image-box-wrap {
    position: relative;
    overflow: auto;
}

.image-box {
    width: 100%;
    opacity: 1;
    float: left;
    margin-top: 12.5%;
    .product-image-container {
        width: 100%;
        float: left;
        overflow-y: hidden;
        position: relative;
    }
    .product-image {
        width: 70%; 
        height: auto;
        float: left;
        @include position(relative, auto auto auto 50%);
        @include transform(translateX(-50%));
        &.zoom-active {
            opacity: 0;
        }
    }
}

.hover-container {
    height: 3em;
    float: left;
    margin-left: 50%;
    margin-top: 20px;
    @include transform(translateX(-50%));
    
    @media all and (min-width: 700px) {
        @include position(absolute, auto auto 8% 24%);
        margin-left: 0;
    }

    @media all and (min-width: 64em) {
        bottom: -5%;
    }

    li {
        float:left;
    }
    li:last-of-type {
        margin-left: 0.5em;
    }
    svg {
        width: 3em;
        height: 3em;
    }
}

.hover-tip {
    color: $roughwood-brown;
    font-family: $font-body;
    font-size: 1.3em;
    line-height: 2em;
}
.return-button {
    @include appearance(none);
    background: transparent;
    outline: none;
    border: none;
    @include position(absolute, 60px 10px auto auto);
    font-family: $font-roughwood;
    font-size: 18px;
    color: $product-background;
    display: none;
}
.product-information {
    width: 100%;
    float: left;
    margin-top: 80px;
}
.product {
    .description {
        color: #FFF;
        font-family: $font-body;
        list-style-type: none;
        font-size: 40px;
        width: 100%;
        padding: 0 5%;
        float: left;
        text-align: left;
        li {
            float: left;
        }
        .price {
            font-size: 20px;
            margin-top: 10px;
            width: 100%;
            color: $product-background;
        }
        .sale-price {
            text-decoration: line-through;
        }
        .product-name {
            width: 100%;
            font-family: $font-roughwood;
            color: $product-background;
        }
    }
    .colors {
        height: auto;
        width: 100%;
        padding: 10px 0;
        margin-top: 20px;
        border-top: 1px solid $product-background;
        border-bottom: 1px solid $product-background;
        float: left;
        .color-swatch {
            height: 40px;
            width: 40px;
            background: $roughwood-brown;
            margin: 5px 3px;
            float: left;
            cursor: pointer;
            position: relative;
            list-style-type: none;
            border-radius: 50%;
            box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.26);
            @include transition-duration(0.15s);
            a {
                @include position(absolute, 0px 0px 0px 0px);
            }
        }
        .color-swatch:nth-of-type(1) {
            margin-left: 0;
        }
        .active {
            border: 2px solid $light;
        }
    }
    .size {
        margin-top: 0;
        line-height: 40px;
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid $product-background;
        ul {
            width: 100%;
            li {
                height: 100%;
                list-style-type: none;
                width: auto;
                display: inline;
                font-size: 20px;
                height: 40px;
                text-align: center;
                cursor: pointer;
                line-height: 40px;
                color: $product-background;
                margin: 0 5px;
                @extend %default-transition;

            }
            li.circle {
                border-radius: 50%;
                border: 2px solid $product-background;
                box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.26);
                width: 40px;
                position: relative;
                span {
                    @extend %absolute-middle;
                }
            }
            li:nth-of-type(1) {
                margin-left: 0;
            }
        }
        .circle.active,
        .active {
            color: $roughwood-grey;
            border-color: $roughwood-grey;
        }
        .circle.no-inventory {
            opacity: 0.5;
        }
    }
}
.product-description {
    width: 100%;
    margin-top: 20px;
    font-size: 20px;
    margin-left: 0;
    cursor: pointer;
    color: $product-background;
    span {
        position: relative;
        &:after {
            content: "+";
            @include position(absolute, 50% -10% auto auto);
            @include transform(translateY(-60%));
        }
    }
}
.product-bullets {
    width: 100%;
    margin: 10px 0;
    max-height: 0px;
    font-size: 16px;
    transition-duration: 0.4s;
    color: $product-background;
    overflow: hidden;
    ul {
        li {
            margin: 15px 0;
            width: 100%;
        }
    }
}
.product-bullets-show {
    max-height: 200px;
}
.purchase-container {
    width: 100%;
    margin-top: 10px;
    @extend %default-transition;
    .show-related:nth-of-type(2) {
        margin-top: 0px;
    }
}
.purchase-button {
    font-size: 16px;
    display: block;
    line-height: 40px;
    width: 100%;
    color: $product-background;
    background: transparent;
    font-family: $font-body;
    float: left;
    text-align: left;
    transition-duration: 0.3s;
    cursor: pointer;
    position: relative;
    span {
        position: relative;
        &:after {
            content: "+";
            line-height: 40px;
            font-size: 16px;
            @include position(absolute, 50% -15% auto auto);
            @include transform(translateY(-50%));
        }
    }
}
.notification-tag {
    @include position(absolute, 50% auto auto 130%);
    @include transform(translateY(-50%));
    font-family: $font-body;
    color: $roughwood-grey;
    width: 100px;
}
.size {
    .notification-tag {
        @include position(relative, auto auto auto auto);
        @include transform(none);
        font-size: 16px;
        float: left;
    }
}
.show-related {
    @include appearance(none);
    outline: none;
    border: none;
    font-size: 18px;
    display: block;
    line-height: 32px;
    width: 100%;
    color: $product-background;
    font-family: $font-body;
    float: left;
    background: transparent;
    text-align: left;
    transition-duration: 0.3s;
    cursor: pointer;
    margin-top: 20px;
    @extend %default-transition;
    span {
        position: relative;
        float: left;
        &:after {
            content: "+";
            @include position(absolute, 50% -10% auto auto);
            @include transform(translateY(-50%));
        }
    }
}
.icon-filler-2 {
    width: 80%;
    max-height: 65px;
    display: block;
    margin: 0 auto;
    use {
        fill: $product-background;
    }
}
.related-products {
    h2 {
        font-family: $font-roughwood;
        color: #FFF;
        background: $roughwood-brown;
        font-size: 16px;
        width: 100%;
    }
    h3 {
        font-family: $font-roughwood;
        color: #FFF;
        text-align: center;
        font-size: 28px;
        line-height: 36px;
        width: 100%;
        padding: 0 5%;
        @extend %absolute-middle;
    }
}
@media all and (min-width: 600px) {
    .related-products {
        h2 {
            font-size: 20px;
        }
    }
}
@media all and (min-width: 700px) {
    .breadcrumbs {
        li {
            font-size: 20px;
        }
    }
    .image-box,
    .product-information {
        width: 50%;
    }
    .product-information {
        margin-top: 90px;
    }
    .image-box {
        margin-top: 50px;
        max-height: 600px;
        .product-image {
            width: auto;
            height: auto;
            max-height: 600px;
        }
    }
    .product {
        min-height: 100vh;
        .description {
            padding: 0 10%;
        }
    }
}
.no-touchevents {
    .show-related:hover {
        color: $roughwood-grey;
    }
    .purchase-button:hover {
        color: $roughwood-grey;
    }
    .related-products {
        .looks-modal .close-items:hover {
            background-color: $roughwood-grey-dark;
        }
    }
}
div::-webkit-scrollbar { 
    display: none;
}

