ul.mobile-nav,
ul.user-nav {
  width: 100%;
  float: left;
  p {
    font-size: 1.6em;
    letter-spacing: 2px;
  }

  > li {
    width: 100%;
    float: left;
    text-align: center;
    padding: 1.5em 0;
    border-bottom: 1px solid rgba($roughwood-grey, 0.15);
    position: relative;
    a {
      color: inherit;
    }
  }
  > li:last-child {
    border-bottom: none;
  }
  p.nav-item {
    position: relative;
    &:after {
      content: '';
      width: 1em;
      height: 1em;
      border-bottom: 1px solid $roughwood-grey;
      border-right: 1px solid $roughwood-grey;
      @include position(absolute, -5px 10px auto auto);
      @include transform(rotate(45deg));
    }
    &.clothing:after {
      display: none;
    }
  }
  p.scripty {
    font-size: 2.2em;
    font-family: $font-roughwood;
  }
}
ul.mobile-nav {
  ul.sub-nav {
    padding: 0;
    max-height: 0px;
    overflow-y: hidden;
    @include transition(0.5s);
    > li {
      width: 100%;
      float: left;
      padding: 1em 0;
      border-bottom: 1px solid rgba($roughwood-grey, 0.15);
      &:last-child {
        border-bottom: none;
      }
      > p {
        text-align: left;
        padding-left: 1em;
        span {
          position: relative;
          &:after {
            content: '';
            width: 0.75em;
            height: 0.75em;
            border-bottom: 1px solid $roughwood-grey;
            border-right: 1px solid $roughwood-grey;
            @include position(absolute, 0px -30px auto auto);
            @include transform(rotate(45deg));
          }
        }
      }
    }
    &.no-arrow {
      > li {
        > p span:after {
          display: none;
        }
      }
    }
    &.expand {
      max-height: 2000px;
    }
    .sort-item {
      button {
        margin: 0 1em;
      }
    }
    &.collections-nav {
      > li {
        a.cta {
          color: $light;
          display: block;
          max-width: 150px;
          margin: 1em auto;
        }
      }
    }
  }
  ul.child-nav {
    padding: 0;
    padding-top: 1.5em;
    max-height: 0px;
    overflow-y: hidden;
    @include transition(0.5s);
    > li {
      width: 50%;
      float: left;
      padding: 0.5em 0;
    }
    &.expand {
      max-height: 2200px;
    }
  }
  a.child-item {
    display: block;
    border: 1px solid $roughwood-grey;
    width: auto;
    max-width: 80%;
    margin: 0 auto;
    padding: 0.5em 0;
  }
  .filter-active {
    a {
      background: $roughwood-grey;
      p {
        color: $roughwood-brown;
      }
    }
  }
}
@media all and (min-width: 64em) {
  ul.mobile-nav {
    display: none;
  }
}

ul.user-nav {
  border-top: 2px solid rgba($roughwood-grey, 0.15);
  margin-top: 2em;
  a.cta {
    font-size: 1em;
    display: block;
    max-width: 10em;
    margin: 0 auto;
    margin-top: 1em;
  }
  > li {
    @extend %default-transition;
  }
}

.no-touchevents {
  ul.user-nav li:hover:not(:last-child):not(:first-child) {
    background-color: $roughwood-grey;
    p {
      color: $roughwood-brown;
    }
  }
  a.cta:hover {
    p {
      color: $roughwood-brown;
    }
  }
}
