.collections {
    width: 100%;
    float: left;
    margin-top: 50px;
    position: relative;
    min-height: 100vh;
    background-color: $product-background;
    .icon-roughwood-tree {
        width: 100%;
        height: 100vh;
        @extend %absolute-middle;
        position: fixed;
        z-index: 0;
        use {
            width: 100%;
            fill: rgba($roughwood-brown, 0.15);
        }
    }
}
.icon-line-11 {
    width: 80%;
    max-width: 700px;
    height: auto;
    max-height: 40px;
    display: block;
    margin: 0 auto;
    use {
        fill: $roughwood-brown-nav;
    }
}
.view-collections {
    background: transparent;
    font-family: $font-roughwood;
    color: $light;
    padding: 5px;
    border: 1px solid $light;
    font-size: 18px;
    line-height: 22px;
    display: block;
    z-index: 10;
    max-width: 150px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 15px;
    text-decoration: none;
    @extend %default-transition;
}
.no-touchevents {
    .view-collections:hover {
        color: $roughwood-brown;
        border-color: $roughwood-brown;
    }
}
.collections-products {
    width: 100%;
    float: left;
    background: transparent;
    position: relative;
    h2 {
        font-size: 28px;
        font-family: $font-roughwood;
        color: $light;
        width: 100%;
        text-align: center;
    }
    h3 {
        font-size: 1.6em;
        font-family: $font-body;
        color: $roughwood-brown;
        width: 100%;
        text-align: center;
        @include position(absolute, auto auto 12.5% auto);
    }
    .slick-slider {
        float: left;
        height: 400px;
        width: 100%;
        position: relative;
        margin-bottom: 0px;
        .slick-slide {
            height: 400px;
            position: relative;
            cursor: pointer;
            img {
                height: 90%;
                width: auto;
                float: left;
                @include position(relative, auto auto auto 50%);
                @include transform(translateX(-50%));
            }
            .name {
                @include position(absolute, 5px auto auto 12.5%);
                font-size: 20px;
                color: $roughwood-grey;
                font-family: $font-body;
                opacity: 0;
                @extend %default-transition;
                z-index: 10;
            }
            .price {
                font-size: 14px;
                color: $roughwood-grey;
                font-family: $font-body;
                opacity: 0;
                @extend %default-transition;
                @include position(absolute, 30px auto auto 12.5%);
                z-index: 10;
            }
        }
        .slick-next,
        .slick-prev {
            height: 30px;
            width: 60px;
            border-radius: 90px 90px 0 0;
            background: transparent;
            z-index: 100;
            @extend %default-transition;
            &:before {
                content:"";
                width: 2px;
                height: 20px;
                opacity: 1;
                background: $roughwood-brown;
                @include position(absolute, 50% auto auto 33%);
                @include transform(translateY(-50%) rotate(45deg));
                @extend %default-transition;
            }
            &:after {
                content:"";
                width: 2px;
                height: 20px;
                background: $roughwood-brown;
                @include position(absolute, auto 40% 50% auto);
                @include transform(translateY(50%) rotate(-45deg));
                @extend %default-transition;
            }
        }
        .slick-next {
            @include position(absolute, 40px 5px auto auto);
            @include transform(translateY(-50%) rotate(90deg));
        }
        .slick-prev {
            @include position(absolute, 36px 45px auto auto);
            @include transform(translateY(-50%) rotate(270deg));
        }
        .slick-dots {
            bottom: 30px;
            button {
                &:before {
                    content: "\2666 \FE0E";
                    font-size: 14px;
                    color: $roughwood-grey;
                    opacity: 1;
                }
            }
            .slick-active {
                button {
                    &:before {
                        color: $roughwood-brown;
                        opacity: 1;
                    }
                }
            }
        }
    }
    p.counter {
        font-family: $font-body;
        font-size: 3em;
        color: $roughwood-brown;
        @include position(absolute, 5px 50px auto auto);
    }
}
@media all and (min-width: 950px) {
    .collections-products p.counter {
        display: none;
    }
}
// .collections-slick {
//     > div:not(:first-of-type) {
//         display: none;
//     }
//     > div {
//         width: 100%;
//         float: left;
//         img {
//             width: 85%;
//             height: auto;
//             margin: 0 7.5%;
//             margin-top: 5%;
//         }
//         .name {
//             @include position(absolute, 5px auto auto 12.5%);
//             font-size: 20px;
//             color: $roughwood-grey;
//             font-family: $font-body;
//             opacity: 0;
//             @extend %default-transition;
//             z-index: 10;
//         }
//         .price {
//             font-size: 14px;
//             color: $roughwood-grey;
//             font-family: $font-body;
//             opacity: 0;
//             @extend %default-transition;
//             @include position(absolute, 30px auto auto 12.5%);
//             z-index: 10;
//         }
//     }
// }
// @media all and (min-width: 700px) {
//     .collections-slick > div {
//         display: block;
//         img {
//             width: auto;
//             margin: 0;
//             margin-top: 5%;
//         }
//     }
// }
.no-touchevents {
    .collections-products .slick-slider .slick-slide:hover {
        .name,
        .price {
            opacity: 1;
        }
    }
}
.touchevents {
    .collections-products .slick-slider .slick-slide {
        .name,
        .price {
            opacity: 1;
        }
    }
}