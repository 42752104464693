// // // // // // // // // // //
//
// Main Nav
//
// // // // // // // // // // //

.main-nav {
  background: $roughwood-brown-nav;
  font-family: $font-nav;
  font-style: normal;
  position: fixed;
  height: 50px;
  width: 100%;
  text-align: center;
  z-index: 1000;
  box-shadow: 0 3px 5px 0 rgba($roughwood-brown, 0.5);
  .content {
    position: relative;
    max-width: none;
  }
  .nav-control-container {
    @extend %default-transition;
    @include position(absolute, 0px 0px auto auto);
    height: 50px;
    width: 50px;
    cursor: pointer;
    .icon-avatar {
      @include position(absolute, 5px auto auto -0px);
      fill: $roughwood-grey;
      width: 36px;
      height: 36px;
      display: none;
    }
  }
  .icon-roughwood-logo {
    @include animation(none);
    opacity: 1;
  }

  .icon-roughwood-logo {
    @include position(absolute, 0px auto auto 50%);
    @include transform(translateX(-50%));
    display: none;
    height: 50px;
    width: 250px;
    margin-top: 0;
    z-index: 10;
    use {
      fill: $roughwood-grey;
    }
  }
  .icon-r {
    @include position(absolute, 5px auto auto 5px);
    width: 40px;
    height: 40px;
    use {
      fill: $roughwood-grey;
    }
  }
  .bag {
    @include position(absolute, 7.5px 65px auto auto);
    width: 30px;
    height: 30px;
    z-index: 5;
    cursor: pointer;
    use {
      fill: $roughwood-grey;
    }
  }
  .cart-amount {
    @include position(absolute, 15px 76px auto auto);
    font-family: $font-body;
    font-size: 16px;
    color: $roughwood-grey;
    cursor: pointer;
  }
}
.no-touchevents {
  .my-profile:hover {
    color: $roughwood-brown;
  }
  .log-out:hover {
    background-color: transparent;
    border-color: $roughwood-brown-nav;
    color: $roughwood-brown;
  }
  .profile-container {
    .my-profile:hover {
      color: $roughwood-grey-dark;
    }
  }
}
@include mediaMin(600px) {
  .main-nav {
    .icon-r {
      display: none;
    }
    .icon-roughwood-logo {
      display: block;
    }
  }
}
@include mediaMin(64em) {
  .main-nav {
    .icon-roughwood-logo {
      float: left;
      @include position(relative, auto auto auto auto);
      @include transform(none);
      margin: 0;
      margin-left: 7px;
    }
    .nav-control-container .icon-avatar {
      display: block;
    }
  }
}
