// - - - - - - - - - - - - - -
// Close Login Modal Button
// - - - - - - - - - - - - - -
.close-login {
  @extend %default-transition;
  @include appearance(none);
  background-color: transparent;
  position: absolute;
  top: 85px;
  right: 15px;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  z-index: 99999;

  @include mediaMin(500px) {
    right: 25px;
  }
  @include mediaMin($modal-collapse) {
    top: 90px;
    right: 50px;
  }
  &:before,
  &:after {
    @extend %default-transition;
    @include position(absolute, 0px auto auto 50%);
    content: '';
    background-color: #999;
    height: 30px;
    width: 2px;
    @include mediaMin($modal-collapse) {
      height: 45px;
    }
  }
  &:after {
    @include transform(rotate(45deg));
  }
  &:before {
    @include transform(rotate(-45deg));
  }
}
