.lookbook {
    width: 100%;
    @include clearfix();
    position: relative;
    top: 50px;
    &:after {
        content: "";
        background-color: $product-background;
        height: 100vh;
        width: 100%;
        @include position(fixed, 0 auto auto 0);
        z-index: -2;
    }
    .content {
        max-width: none;
    }
    .each-look {
        width: 100%;
        padding-bottom: 109.80966325%;
        position: relative;
        @include transition(1s);
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        &.preview {
            cursor: auto;
        }
        &:after {
            content: "";
            height: 100%;
            width: 100%;
            @include position(absolute, 0px auto auto 0px);
            background: rgba($roughwood-brown, 0.3);
            opacity: 0;
            @include transition(all .6s ease-in-out);
        }
        h2 {
            color: $roughwood-grey;
            font-size: 2em;
            font-family: $font-roughwood;
            @include position(absolute, 15px auto auto 15px);
            opacity: 0;
            @extend %default-transition;
        }
        h3 {
            color: $roughwood-grey;
            font-family: $font-body;
            font-size: 1.6em;
            width: 100%;
            text-align: center;
            padding: 0.5em 1.25em;
            opacity: 0;
            @extend %default-transition;
            @include position(absolute, 62.5% auto auto auto);
            z-index: 3;
            span {
                position: relative;
                &:after {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background-color: $roughwood-grey;
                    @include position(absolute, -0.5em auto auto 0px);
                }
            }
        }
    }
    .each-look:nth-child(1) {
        margin-top: 0;
    }
}
.touchevents {
    .lookbook {
        .each-look {
            &:after {
                opacity: 1;
            }
            h2,h3 {
                opacity: 1;
            }
            p {
                opacity: 1;
            }
        }
    }
}
.lookbook {
    .filter {
        width: 100%;
        height: 0px;
        background-color: rgba(#000, 0.6);
        @include position(fixed, 0px auto auto 0px);
        z-index: 100;
        overflow: hidden;
    }
    .show-lookbook-modal {
        height: 100vh;
    }
}

.no-scroll {
    overflow: hidden;
}
// LOOKBOOK MODAL
.looks-modal {
    width: 100%;
    @extend %absolute-middle;
    background-color: $product-background;
    z-index: 101;
    max-width: 100em;
    h2 {
        width: 100%;
        text-align: center;
        font-family: $font-body;
        background-color: $roughwood-brown;
        color: $roughwood-grey;
        line-height: 40px;
        font-size: 22px;
        margin-top:0px;
    }
    .close-items {
        @include appearance(none);
        @include position(absolute, 0px 0px auto auto);
        width: 40px;
        height: 40px;
        background: transparent;
        border: none;
        outline: none;
        @extend %default-transition;
        &:after {
            content: "";
            height: 26px;
            width: 2px;
            background: $roughwood-grey;
            @include position(absolute, 50% auto auto 50%);
            @include transform(translateY(-50%) translateX(-50%) rotate(45deg));
            @extend %default-transition;
        }
        &:before {
            content: "";
            height: 26px;
            width: 2px;
            background: $roughwood-grey;
            @include position(absolute, 50% auto auto 50%);
            @include transform(translateY(-50%) translateX(-50%) rotate(-45deg));
            @extend %default-transition;
        }
    }
}

@media all and (min-width: 800px) {
    .lookbook {
        .each-look {
            float: left;
            width: 50%;
            padding-bottom: 54.904831625%;
            height: auto;
            h2 {
                font-size: 3em;
            }
            h3 {
                font-size: 2em;
            }
        }
        .each-look:nth-child(1),
        .each-look:nth-child(2) {
            margin-top: 0;
        }
    }
}

.no-touchevents {
    .each-look:hover {
        &:after {
            opacity: 1;
        }
        h2,h3 {
            opacity: 1;
        }
    }
    .modal-present:hover {
        &:after {
            opacity: 0;
        }
    }
    .close-items:hover {
        background: $product-background;
        &:after {
            background: $roughwood-brown;
        }
        &:before {
            background: $roughwood-brown;
        }
    }
}
